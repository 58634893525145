import axios from "axios";

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  });

  axiosClient.interceptors.request.use(
    (config:any) => {
      const userdetails = localStorage.getItem("nbcuserdetail");  
      let token = "";
      if(userdetails)   {        
        token =(JSON.parse(userdetails)).accessToken;
      } 
      return {
        ...config,
        headers: {
          ...(token !== null && { Authorization: `Bearer ${token}` }),
          ...config.headers,
        },
      };
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  
  axiosClient.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        localStorage.removeItem("nbcuserdetail");
        window.location.href = "/";
      }
      return Promise.reject(error);
    }
  );
  
  export default axiosClient;