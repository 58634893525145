import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ReportContextData } from "../../layouts/reporting/ReportsLayout";

type BreadcrumbProps = {
  breadCrumbData: ReportContextData
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ breadCrumbData }) => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const [breadCrumbPaths, setBreadCrumbPaths] = useState<Partial<BreadcrumbPath[]>>([]);

  type BreadcrumbPath = {
    text: string,
    path: string
  }

  useEffect(() => {
    let paths: BreadcrumbPath[] = [];
    if (breadCrumbData) {
      if (breadCrumbData.data.level >= 1) {
        paths.push({ text: "Report menu", path: `/nbc/reporting` })
        paths.push({ text: "Region", path: `/nbc/reporting/region` })
      }
      if (breadCrumbData.data.level >= 2) {
        paths.push({ text: breadCrumbData.data.regionName, path: `/nbc/reporting/region/${breadCrumbData.data.regionCode}/zone` })
      }

      if (breadCrumbData.data.level >= 3) {
        paths.push({ text: breadCrumbData.data.zoneName, path: `/nbc/reporting/region/${breadCrumbData.data.regionCode}/zone/${breadCrumbData.data.zoneCode}/district` })
      }

      if (breadCrumbData.data.level >= 4) {
        paths.push({ text: breadCrumbData.data.districtName, path: `/nbc/reporting/region/${breadCrumbData.data.regionCode}/zone/${breadCrumbData.data.zoneCode}/district/${breadCrumbData.data.districtCode}` })
      }

      setBreadCrumbPaths(paths);
    }
    setBreadCrumbPaths(paths);
  }, [breadCrumbData])

  return (
    <div className={breadCrumbData.data.level>0?"mt-5":""}>
      {breadCrumbPaths.map((value, index: number) => {
        const last = index === breadCrumbPaths.length - 1;
        return (
          last ? (
            <span key={value ? value.path : index}>&nbsp;&gt;&gt; {value ? value.text : ""}</span>
          ) :
            <span key={value ? value.path : index}>
             {index > 0?<span> &nbsp;&gt;&gt;</span>:""} <Link to={value ? value.path : ""}>{value ? value.text : ""}</Link>
            </span>)
      })}
    </div>
  );
};

export default Breadcrumb;