import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import LoginPage from './components/LoginPage';
import { deliveryClient } from './api/cmsDeliveryClient';
import NBCLayout from './components/NBCLayout';
import ReportsLayout from './layouts/reporting/ReportsLayout';
import Reports from './pages/reports/Reports';
import RegionDetails from './pages/reports/RegionDetails';
import ZoneDetails from './pages/reports/ZoneDetails';
import DistrictDetail from './pages/reports/DistrictDetails';
import Details from './pages/reports/Details';
import NBCDetails from './components/NBCDetails';
import Enrollment from './components/Enrollment';
import { UserContext } from "./common/UserContext";
import { UserData } from './models/User';
import { PrivateRoutes } from './common/PrivateRoutes';
import CandidateReport from './pages/reports/CandidateReport';
import FieldEnrollReport from './pages/reports/FieldEnrlReport';
import CorporateEnrollReport from './pages/reports/CorporateEnrollReport';
import DistributorAndVendorReport from './pages/reports/DistributorAndVendorReport';
import SBRReport from './pages/reports/SBRReport';
import TravellerReport from './pages/reports/TravellerReport';


function App() {
  const [loginPageData, setLoginPageData] = useState<any>(null);
  const [pageLayoutElements, setPageLayoutElements] = useState<any>(null);
  const [userData, updateUserData] = useState<UserData>({ isDealer: false, executiveManager: "", generalManager:"", authorizationPolicy: [], accessCode:"" });
  const [hasFieldReportAccess, setHasFieldReportAccess] = useState(false)
  const [hasCorporateReportAccess, setHasCorporateReportAccess] = useState(false)

  const setUserData = (data: UserData) => {
    updateUserData(data);
  }

  const value = { userData, setUserData }

  // Retrieves the Website root item and saves the linked Landing page
  useEffect(() => {
    const userdetails = localStorage.getItem("nbcuserdetail");
    if (userdetails) {
      let userdetail = JSON.parse(userdetails).dealerInfo;
      setUserData(userdetail);
      let authorizationPolicies = JSON.parse(userdetails).authorizationPolicy as string[];
      if (authorizationPolicies.includes("field")) {
        setHasFieldReportAccess(true)
      }

      if (authorizationPolicies.includes("corporate")) {
        setHasCorporateReportAccess(true)
      }
    }

    deliveryClient.items()
      .inFilter("system.type", ["login", "page_layout"])
      .toPromise()
      .then((response) => {
        const loginData = response.data.items.filter(w => w.system.type == "login")[0];
        const layoutData = response.data.items.filter(w => w.system.type == "page_layout")[0];
        setLoginPageData(loginData);
        setPageLayoutElements(layoutData);
      })
  }, [])

  useEffect(() => {
    const userdetails = localStorage.getItem("nbcuserdetail");
    if (userdetails) {
      let authorizationPolicies = JSON.parse(userdetails).authorizationPolicy as string[];
      if (authorizationPolicies.includes("field")) {
        setHasFieldReportAccess(true)
      }

      if (authorizationPolicies.includes("corporate")) {
        setHasCorporateReportAccess(true)
      }
    }
  }, [userData])

  return (
    <UserContext.Provider value={value}>
      <BrowserRouter>
        <Routes>
          <Route index element={<LoginPage loginPageData={loginPageData} headerPageData={pageLayoutElements} />} />
          <Route element={<PrivateRoutes />}>
            <Route path='nbc' element={<NBCLayout />} >
              <Route index path='welcome' element={<NBCDetails />}></Route>
              <Route path='enrollment' element={<Enrollment />}></Route>
              {(hasFieldReportAccess || hasCorporateReportAccess) &&
              <Route path='reporting' element={<ReportsLayout />}>
                <Route index element={<Reports />} />
                <Route
                  path="region"
                  element={<RegionDetails />}
                />
                <Route
                  path="region/:region/zone"
                  element={<ZoneDetails />}
                />
                <Route
                  path="region/:region/zone/:zone/district"
                  element={<DistrictDetail />}
                />
                <Route
                  path="region/:region/zone/:zone/district/:id"
                  element={<Details />}
                />
                <Route
                  path="candidate"
                  element={<CandidateReport />}
                />
                <Route
                  path="field"
                  element={<FieldEnrollReport />}
                />
                {!hasCorporateReportAccess && <Route
                  path="*"
                  element={<Navigate to={"/nbc/welcome"} />}
                />}
                {hasCorporateReportAccess && <>
                  <Route
                    path="corporate"
                    element={<CorporateEnrollReport />}
                  />
                  <Route
                    path="distributor"
                    element={<DistributorAndVendorReport />}
                  />
                  <Route
                    path="sbr"
                    element={<SBRReport />}
                  />
                  <Route
                    path="traveller"
                    element={<TravellerReport />}
                  />
                  <Route
                    path="*"
                    element={<Navigate to={"/nbc/welcome"} />}
                  />
                </>
                }
              </Route>
            }
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </UserContext.Provider>
  );
}

export default App;
