import React, { useContext, useEffect, useState } from 'react';
import { EnrollmentPage } from '../models';
import { deliveryClient } from "../api/cmsDeliveryClient";
import './Enrollment.css';
import axiosClient from '../api/axios';
import { useOutletContext } from "react-router-dom";
import { ContextData } from "./NBCLayout";
import { UserContext } from '../common/UserContext';
import EnrollVirtual from './EnrollVirtual';

const Enrollment = () => {
    const userData = useContext(UserContext);
    const [data, setData] = useState<Partial<EnrollmentPage>>({});
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [errors, setErrors] = useState<string[]>([]);
    const [isVirtualReg, setIsVirtualReg] = useState(false);
    const [displayForm, setDisplayForm] = useState(!userData?.userData?.isDealer);
    const [displayRegTypeDetail, setDisplayRegTypeDetail] = useState(false);
    const [selectedMgrType, setSelectedMgrType] = useState("");
    const { setActiveTab } = useOutletContext<ContextData>();

    useEffect(() => {
        setActiveTab("enrollment");
        setErrors([]);
        deliveryClient.item<EnrollmentPage>('enrolment_item')
            .toPromise()
            .then(
                response => {
                    setData(response.data.item);
                }
            )

        const userdetails = localStorage.getItem("nbcuserdetail");
        if (userdetails) {
            const isVirtual = (JSON.parse(userdetails)).programInfo?.isVirtual as boolean;
            setDisplayRegTypeDetail(isVirtual);
        }

        return ()=>{setSelectedMgrType("")}
    }, [])

    useEffect(() => {
        setDisplayForm(!userData?.userData?.isDealer)
    }, [userData])

    const enrol = (isManager: boolean) => {
        setErrors([]);
        let valErrors: string[] = [];
        if (!isManager) {
            if (!firstName) {
                valErrors.push(data.elements?.first_textbox_required_error.value ?? "");
            }
            else if (firstName.length > 30) {
                valErrors.push(data.elements?.first_textbox_maxlength_error.value ?? "")
            }

            if (!lastName) {
                valErrors.push(data.elements?.second_textbox_required_error.value ?? "");
            }
            else if (lastName.length > 50) {
                valErrors.push(data.elements?.second_textbox_maxlength_error.value ?? "")
            }
        }
        if (valErrors.length > 0) {
            setErrors(valErrors);
            return;
        }
        let apidata = {
            firstName: isManager ? "" : firstName,
            lastName: isManager ? "" : lastName,
            isManager: isManager,
            isVirtual: isVirtualReg,
            isGeneralManager: selectedMgrType === 'GM'
        }
        axiosClient.post("api/Enrollment/GetCventLink", apidata).then((response) => {
            if (response?.data?.succeeded) {
                window.open(response?.data?.data,"_blank");
            } else {
                setErrors([...errors, "Something went wrong. Please try again"]);
            }
        }).catch(error => {
            setErrors([...errors, "Something went wrong. Please try again"]);
        })
    }

    const handleChange = (e: any) => {
        setSelectedMgrType(e.target.value);
    }

    const handleNoSelected = () =>{
        setDisplayForm(true);
        setSelectedMgrType("OTH");
    }

    return <div>
        <div className="container">
            {!displayForm ?
                <>
                    <div className='row mt-4 mb-4 virtualattend'>
                        <h4>Select your title</h4>
                        <label>
                            <input type="radio" value="EM" checked={selectedMgrType === 'EM'} onChange={handleChange} />
                            Executive Manager
                        </label>
                        {userData.userData.generalManager && userData.userData.generalManager.trim() && userData.userData.executiveManager !== userData.userData.generalManager &&
                            <label>
                                <input type="radio" value="GM" checked={selectedMgrType === 'GM'} onChange={handleChange} />
                                General Manager
                            </label>}
                        <label>
                            <input type="radio" value="OTH" checked={selectedMgrType === 'OTH'} onChange={handleChange} />
                            Other
                        </label>
                        {selectedMgrType === 'OTH' ? <div className='mt-2 mb-4'> <button type='button' className='btn btn-primary' onClick={() => setDisplayForm(true)}>{data?.elements?.button_text.value}</button></div> :
                            selectedMgrType && <section>
                                <p className='mt-2'>{"Are you " + (selectedMgrType === "EM" ? userData.userData.executiveManager : userData.userData.generalManager) + "?"}</p>
                                <div className='mt-2 mb-4'>
                                    <button className='btn btn-primary' onClick={() => enrol(true)}>Yes</button>
                                    <button className='dealernobtn btn btn-primary' onClick={handleNoSelected}>No</button>
                                </div>
                            </section>
                        }
                        {displayRegTypeDetail &&
                            <div>
                                <EnrollVirtual setIsVirtualReg={setIsVirtualReg} />
                            </div>
                        }
                    </div>
                </>
                :
                <div className="row mt-4">
                    <h4>{data.elements?.title.value}</h4>
                    {data && data.elements &&
                        <>
                            <p className="">{data.elements?.header.value}</p>
                            <div className="enrolinput">
                                <input className="form-control mb-4" type='text' maxLength={30} placeholder={data.elements.first_textbox_placeholder.value} onChange={(e) => { setFirstName(e.target.value) }}></input>
                                <input className="form-control" type='text' maxLength={50} placeholder={data.elements.second_textbox_palceholder.value} onChange={(e) => { setLastName(e.target.value) }}></input>
                                <button type='button' className='btn btn-primary mt-3 mb-3' onClick={() => enrol(false)}>{data.elements.button_text.value}</button>
                            </div>
                            {errors.length > 0 &&
                                <div>
                                    <ul className='errorsummary'>
                                        {errors.map((e, i) => {
                                            return <> <li>{e}</li></>
                                        })}
                                    </ul>
                                </div>
                            }

                        </>}
                    {displayRegTypeDetail &&
                        <div>
                            <EnrollVirtual setIsVirtualReg={setIsVirtualReg} />
                        </div>
                    }
                </div>
            }
        </div>
    </div>
}

export default Enrollment;