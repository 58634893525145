import React from "react";
import { TourismPage } from "../models";

type TourismProps = {
    tourismPageData: TourismPage
    title: string
}
const Tourism: React.FC<TourismProps> = ({ tourismPageData, title }) => {

    return (
        <>
            {
                tourismPageData ?
                <>
                    <div className="spacer">
                        <div className="container">
                            <div className="row">
                                <div className="row justify-content-center">
                                    <div className="col-lg-7 col-md-9 text-center">
                                        <h2 className="title">{tourismPageData.elements.title.value}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <h4>{tourismPageData.elements.heading.value}</h4>
                                <p className="" dangerouslySetInnerHTML={{ __html: tourismPageData.elements.heading_note.value }} />
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    {tourismPageData.elements.locations.linkedItems && tourismPageData.elements.locations.linkedItems.length > 0 &&
                                        <ol type="1">
                                            {tourismPageData.elements.locations.linkedItems.map((l) => {
                                                return <li>{l.elements.name.value}</li>
                                            })}
                                        </ol>
                                    }
                                </div>
                                <div className="col-md-6">
                                    <iframe src={tourismPageData.elements.tourism_map_url.value} width="100%" height="480"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                :<h1>No data to display</h1>
            }

        </>)
}

export default Tourism;