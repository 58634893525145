import React, { useMemo } from 'react'
import { Column, useTable } from 'react-table'
import { Link } from 'react-router-dom'
import { addTotalRow } from '../../utils/utilityfunctions'
import { ReactTable } from '../../common/ReactTable'

export const RegionTable = ({ region, updateBreadcrumbData}: any) => {
  const updateBreadcrumb = (rowData: any) => {
    updateBreadcrumbData(rowData.cell.row.original["region Code"], rowData.cell.row.original["regdescription"])
  }
  const REGION_COLUMNS = [
    {
      Header: 'Region',
      accessor: 'regdescription',
      Cell: (props: any) => props.cell.row.original.displayNonLink ? props.value : <Link to={`/nbc/reporting/region/${props.cell.row.original["region Code"]}/zone`} onClick={() => updateBreadcrumb(props)}>{props.value} </Link>
    },
    {
      Header: 'Total stores number',
      accessor: 'totDealer'
    },
    {
      Header: 'Stores enrolled number',
      accessor: 'enrollDealer'
    },
    {
      Header: 'Percent',
      accessor: 'pctEnrollDlr',
      displayAverage: true
    },
    {
      Header: 'Stores not enrolled number',
      accessor: 'notEnrolled'
    },
    {
      Header: 'Percent',
      accessor: 'pctnotenrolled',
      displayAverage: true
    },
    {
      Header: 'Stores to reach objective number',
      accessor: 'objreq'
    },
    {
      Header: 'Percent',
      accessor: 'fixPctObjReq',
      displayAverage: true
    },
    {
      Header: 'Stores remaining to reach objective number',
      accessor: 'objCount'
    }
  ]
  const columns = useMemo<Column<any>[]>(() => REGION_COLUMNS, [])

  const dataForReportNew = addTotalRow(columns, region);
  return (
    <>
      {dataForReportNew && dataForReportNew.length > 0 &&
        <ReactTable data={dataForReportNew} columns={columns} fileName="Store Enrollment by Region" sheetName={"Store Enrollment by Region"}/>}
    </>
  )
}