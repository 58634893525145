import { useMemo } from 'react'
import { Column } from 'react-table'
import { ReactTable } from '../../common/ReactTable'

export const DetailTable = ({ region, isVirtual}: any) => {
    const DETAIL_COLUMNS = [
        {
            Header: 'Store code',
            accessor: 'dlrnum',
        },
        {
            Header: 'Store name',
            accessor: 'dealer Name'
        },
        {
            Header: 'Store city/state',
            accessor: 'cityState'
        },
        {
            Header: 'Enrollment status',
            accessor: 'enrlStatusDesc'
        },
        {
            Header: 'Attendee enroll date',
            accessor: 'enrlDate'
        },
        {
            Header: 'Attendee name',
            accessor: 'atdName'
        }
    ]

    if (isVirtual) {
        DETAIL_COLUMNS.push({ Header: "Virtual Attendee", accessor: "virtualAttendee" })
    }

    const columns = useMemo<Column<any>[]>(() => DETAIL_COLUMNS, [isVirtual])

    return (
        <>
            {region &&
                <ReactTable data={region} columns={columns} fileName={"Store Enrollment Detail"} sheetName={"Store Enrollment Detail"} />}
        </>
    )
}