import React, { useEffect, useState, useCallback, useRef } from "react";
import { Navigate, Outlet } from 'react-router-dom'
export const PrivateRoutes = () => {
  const userdetails = localStorage.getItem("nbcuserdetail");
  let token = "";
  if (userdetails) {
    token = (JSON.parse(userdetails)).accessToken;
  }
  return (
    token ? <Outlet /> : <Navigate to='/' />
  )
}