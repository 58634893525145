import React from "react";
import './Footer.css'
import { useLocation, useNavigate } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("nbcuserdetail");
    navigate("/");
  }

  return (
    <div className="footer4 sb-footer b-t">
      <div className="container">
        <div className="f4-bottom-bar">
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex font-14">
                {location.pathname!="/" && 
                <button id="logout-button" onClick={logout}>
                  Logout
                </button>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer;