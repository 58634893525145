export const ReportsData = [
    {id:1, name:"", navigateto:"region"},
    {id:2, name:"", navigateto:"candidate"},
    {id:3, name:"", navigateto:"field"},
    {id:4, name:"", navigateto:"corporate"},
    {id:5, name:"", navigateto:"distributor"},
    {id:6, name:"", navigateto:"sbr"},
    {id:7, name:"", navigateto:"traveller"},
];

export const DetailReportFilterOptions = [
    {label:"All stores", value:"ALL"},
    {label:"Enrolled stores", value:"E"},
    {label:"Not enrolled stores", value:"N"},
]