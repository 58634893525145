import React from 'react';
import { useState } from 'react';
import './navbar.css'
import { Link, useLocation, useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import axiosClient from '../api/axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faSquareXmark } from '@fortawesome/free-solid-svg-icons';
import { HeaderLayoutDetail } from '../models';
import { Tabs } from '../utils/tabs';

type NavBarProps = {
    navBarData: HeaderLayoutDetail[],
    activetab: string,
    updateSelectedTab: Function
}
const Navbar: React.FC<NavBarProps> = ({ navBarData, activetab, updateSelectedTab }) => {
    const [toggle, setToggle] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const handleToggle = () => {
        setToggle(!toggle);
    }

    const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        const tabId = e.currentTarget.id;
        const scrollTabId = e.currentTarget.getAttribute("data-tabid");
        const tabSelectedToUpdate = scrollTabId ? scrollTabId : tabId;
        updateSelectedTab(tabSelectedToUpdate);
        if (tabId.indexOf("enrollment") >= 0) {
            e.preventDefault();
            axiosClient.get("api/Enrollment/GetRevisitURL").then((response) => {
                if (response.data && response.data.data) {
                    window.location.href = response.data.data;
                } else {
                    navigate("/nbc/enrollment")
                }
            })
        }
        if (location.pathname.indexOf("welcome") < 0 && tabId.indexOf("reporting") < 0 && tabId.indexOf("enrollment") < 0) {
            e.preventDefault();
            navigate("/nbc/welcome#attire");
        }
    }

    const checkIfTabToDisplay = (tb: string) => {
        let isTabExist: boolean = false;
        let tabIndex: number = 0;
        navBarData.forEach((e, i) => {
            if (e.elements.tablist.linkedItems.some(a => a.elements.id.value == tb)) {
                isTabExist = true;
                tabIndex = i;
                return;
            }
        });
        return { isTabExist, tabIndex };
    }

    return (
        <div className="topbar">
            <div className="header4">
                <div className="container po-relative">
                    <div className='row'>
                        <nav className="navbar navbar-expand-lg h6-nav-bar">
                            {navBarData && navBarData.length > 0 && <div className="logo"><Link id="home" to="welcome" onClick={handleLinkClick} className="navbar-brand active"><img src={navBarData[0].elements.logo_and_banner.linkedItems[0].elements.logo.value[0].url} alt="Subaru" width={225} height={60} /></Link></div>}
                            <button className='navbar-toggler' onClick={handleToggle}>
                                {toggle ? <FontAwesomeIcon icon={faSquareXmark} /> : <FontAwesomeIcon icon={faBars} />}
                            </button>
                            {navBarData && navBarData.length > 0 &&
                                <div className={toggle ? 'show collapse navbar-collapse hover-dropdown font-14 ms-auto' : 'collapse navbar-collapse hover-dropdown font-14 ms-auto'} id="h6-info">
                                    <ul className="navbar-nav menu ms-auto links">
                                        {Tabs.map((t, index) => {
                                            const { isTabExist, tabIndex } = checkIfTabToDisplay(t.id);
                                            if (isTabExist) {
                                                let dataFromCMSForTab = navBarData[tabIndex].elements.tablist.linkedItems.find(a => a.elements.id.value == t.id)
                                                if (t.isPartOfScroll) {
                                                    return <li className="nav-item" key={"nbcscrolltabs" + index}>
                                                        <a data-tabid={t.id} className={"nav-link " + (activetab == t.id ? "active" : "")} href={t.navigateTo} onClick={handleLinkClick}>{dataFromCMSForTab?.elements.text.value}</a>
                                                    </li>
                                                } else {
                                                    return <li className="nav-item" key={"nbcscrolltabs" + index}>
                                                        <Link id={t.id} className={"nav-link " + (activetab == t.id ? "active" : "")} to={t.navigateTo} onClick={handleLinkClick}>{dataFromCMSForTab?.elements.text.value}</Link>
                                                    </li>
                                                }
                                            }
                                        })}
                                    </ul>
                                </div>
                            }
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar