import React from "react";
import { ConferenceInformation } from "../models";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import './ConferenceInfo.css';


type ConferenceInfoProps = {
    conferenceInfoPageData: ConferenceInformation
}
const ConferenceInfo: React.FC<ConferenceInfoProps> = ({ conferenceInfoPageData }) => {
    return (
        <>
            {conferenceInfoPageData &&
                <div className="spacer bg-light team4">
                    <div className="container">
                        <div className="row justify-content-center m-b-30">
                            <div className="col-lg-7 col-md-9 text-center">
                                <h2 className="title">{conferenceInfoPageData.elements.heading.value}</h2>
                                <h6 className="subtitle text-info ">{conferenceInfoPageData.elements.conference_location_info.value}</h6>
                            </div>
                        </div>
                        <div className="row m-t-40 conference-info">
                            {conferenceInfoPageData.elements.conference_locations && conferenceInfoPageData.elements.conference_locations.linkedItems.length > 0
                                && conferenceInfoPageData.elements.conference_locations.linkedItems.map((loc, index) => {
                                    return <div className="col-md-6 wrap-feature9-box b-r b-b" key={"confloac" + index}>
                                        <div className="card aos-init aos-animate" data-aos="fade-right" data-aos-duration="1200">
                                            <div className="card-body d-flex">
                                                <div className="icon-space align-self-center">
                                                    {/* <img src={loc.elements.location_icon.value[0].url} alt="location" /> */}
                                                    <FontAwesomeIcon className="location-icon" icon={faLocationDot} />
                                                </div>
                                                <div className="align-self-center text-left">
                                                    <h5 className="font-medium">
                                                        <a href="javascript:void(0)" className="linking">{loc.elements.location_name.value}</a>
                                                    </h5>
                                                    <p className="m-t-20 wraptext">
                                                        <p className="" dangerouslySetInnerHTML={{ __html: loc.elements.location_address.value }} />
                                                        <a className="text-megna" href={loc.elements.location_link.value}
                                                            target="_blank">{loc.elements.location_link.value}</a>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })}
                        </div>
                        <div className="row m-t-40 ">
                            <div className="col-md-12">
                                <div className="accordion accordion-flush" id="accordionconfinfo">
                                    {conferenceInfoPageData.elements.conference_notes && conferenceInfoPageData.elements.conference_notes.linkedItems.map((n, index) => {
                                        return (
                                            <div className="accordion-item" key={"confnote" + index}>
                                                <h2 className="accordion-header" id={"confnote-heading" + index}>
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#confnote-collapse" + index} aria-expanded="false" aria-controls={"confnote-collapse" + index}>
                                                        {n.elements.heading.value}
                                                    </button>
                                                </h2>
                                                <div id={"confnote-collapse" + index} className="accordion-collapse collapse" aria-labelledby={"confnote-heading" + index} data-bs-parent="#accordionconfinfo">
                                                    <div className="accordion-body">
                                                        <p className="" dangerouslySetInnerHTML={{ __html: n.elements.content.value }} />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default ConferenceInfo;