import { useEffect, useState } from "react";
import { useLoaderData, useNavigate, useOutletContext, useParams } from "react-router-dom"
import { DistrictTable } from "../../components/Tables/DistrictTable"
import axiosClient from "../../api/axios";
import "./reports.css"
import { ReportContextData } from "../../layouts/reporting/ReportsLayout";

export default function DistrictDetail() {
    const navigate = useNavigate();
    const [reportData, setReportData] = useState({})
    const [exportData,setExportData] = useState([])
    const { data, setData, reportList } = useOutletContext<ReportContextData>();
    const { region, zone } = useParams();
    useEffect(() => {
        let existingContextData = JSON.parse(JSON.stringify(data));
        existingContextData.level = 3;
        setData(existingContextData, reportList);


        let dataForApi = {
            region: region,
            zone: zone,
            district: "",
            select: "ALL"
        }
        const res = axiosClient.post("api/Report/GetEnrollments?reportLevel=Zone", dataForApi).then((res) => {
            res.data.data.dataSet.table.forEach((element: any) => {
                element.region = region;
                element.zone = zone;
            });
            setReportData(res.data.data.dataSet.table);
        });

        axiosClient.post("api/Report/GetStoreData?reportLevel=Zone", dataForApi).then((response) => {
            let dataToExport = response.data.data.dataSet.table;
            let existingContextData = JSON.parse(JSON.stringify(data));
            if (dataToExport && dataToExport.length > 0) {
                dataToExport.push({isFooterInfo:true});
                dataToExport.push({ dlrnum: 'Zone:',['dealer Name']:existingContextData.zoneName, isFooterInfo:true });
                dataToExport.push({ dlrnum: 'Region:',['dealer Name']:existingContextData.regionName, isFooterInfo:true });
            }
            setExportData(dataToExport)
        });
    }, [])

    const updateBreadcrumbData = (distCode: string, distName: string) => {
        let existingContextData = JSON.parse(JSON.stringify(data));
        existingContextData.level = 4;
        existingContextData.districtCode = distCode;
        existingContextData.districtName = distName;
        setData(existingContextData, reportList);
    }

    return (
        <div className="report-details">
            <h2 className="reports_heading">STORE ENROLLMENT REPORT BY DISTRICT</h2>
            <DistrictTable region={reportData} exportData={exportData} updateBreadcrumbData={updateBreadcrumbData} />
        </div>
    )
}