import React, { useRef } from 'react'
import { useTable } from 'react-table'
import './reacttable.css'
import ReactExport from "react-export-excel";

export const ReactTable = ({ data, columns, fileName, sheetName}: any) => {
  const tableRef = useRef(null);
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    columns: columns,
    data: data.filter((r: any) => !r.isFooterInfo)
  })

  return (
    <>
      <div className='row'>
        {data && data.length > 0 && data.some((r: any) => !r.isFooterInfo) ? <>
          <ExcelFile element={<button className='btn btn-secondary exportbtn mb-2 btn-sm'>Export to excel</button>} filename={fileName}>
            <ExcelSheet data={data} name={sheetName}>
              {columns.map((c: any) => {
                return <ExcelColumn label={c.Header} value={c.accessor} />
              })}
            </ExcelSheet>
          </ExcelFile>
          <div className='table-responsive'>
            <table {...getTableProps()} ref={tableRef} className='table'>
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr className='table-info' {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        return <td {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </>
          : <span className='nodataspan'>No data to display</span>}
      </div>
    </>
  )
}