import React from "react";
import { AttirePage } from "../models";
type AttirePageProps = {
    attirePageData: AttirePage
}
const Attire: React.FC<AttirePageProps> = ({ attirePageData }) => {
    return (
        <>
            <div className="spacer">
                <div className="container">
                    <div className="row">
                        <div className="row justify-content-center">
                            <div className="col-lg-7 col-md-9 text-center">
                                <h2 className="title">{attirePageData && attirePageData.elements.title.value}</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4 mt-lg-4">
                        <div className=" justify-content-center">
                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                {attirePageData && attirePageData.elements.headings_and_content && attirePageData.elements.headings_and_content.linkedItems && attirePageData.elements.headings_and_content.linkedItems.map((a, index) => {
                                    return (
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id={"flush-heading" + index}>
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#flush-collapse" + index} aria-expanded="false" aria-controls={"flush-collapse" + index}>
                                                    {a.elements.header.value}
                                                </button>
                                            </h2>
                                            <div id={"flush-collapse" + index} className="accordion-collapse collapse" aria-labelledby={"flush-heading" + index} data-bs-parent="#accordionFlushExample">
                                              
                                                <div className='accordion-body'
                                                    dangerouslySetInnerHTML={{ __html: a.elements.content.value }}>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
}

export default Attire;