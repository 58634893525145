import React, { ChangeEvent, useEffect, useState } from 'react'
import axiosClient from "../../api/axios";
import { useOutletContext } from 'react-router-dom';
import { ReactTable } from '../../common/ReactTable';
import { ReportContextData } from '../../layouts/reporting/ReportsLayout';
import './FieldEnrlReport.css'

type Region = {
    regCode: string,
    regDescription: string
}

type Zone = {
    zoneName:string,
    zoneCode:string,
    zone:string
}

const FieldEnrollReport = () => {
    const { reportList } = useOutletContext<ReportContextData>();
    const [selectedRegion, setSelectedRegion] = useState("all")
    const [selectedZone, setSelectedZone] = useState("all");
    const [selectedRegionName, setSelectedRegionName] = useState("All")
    const [regions, setRegions] = useState<Partial<Region[]>>([]);
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const [reportData, setReportData] = useState([]);
    const [filteredZones, setFilteredZones] = useState<Zone[]>([]);

    useEffect(() => {
        axiosClient.get("api/Report/GetAllRegions").then((response) => {
            setRegions(response.data.data.table);
            setIsPageLoaded(true);
        })
        axiosClient.get("api/Report/GetAllZonesByRegion?regCode=ALL").then((response) => {
            setFilteredZones(response.data.data.table);
        });
    }, []);


    useEffect(() => {
        axiosClient.get("api/Report/GetEnrollmentReportsByType?regCode=all&rptType=1").then((res) => {
            let data = res.data.data.dataSet.table;
            if (data && data.length > 0) {
                data.push({ isFooterInfo: true });
                data.push({ atdName: `Total Attendees:${data[0]["reccount"]}`, isFooterInfo: true });
            }
            setReportData(data);
        });
    }, [])


    useEffect(() => {
        if (isPageLoaded) {
            axiosClient.get(`api/Report/GetAllZonesByRegion?regCode=${selectedRegion}`).then((response) => {
                setFilteredZones(response.data.data.table);
            });

            axiosClient.get(`api/Report/GetFieldReportData?regCode=${selectedRegion}&zone=ALL`).then((response) => {
                let data = response.data.data.dataSet.table;
                if (data && data.length > 0) {
                    data.push({ isFooterInfo: true });
                    data.push({ atdName: `Total Attendees:${data[0]["reccount"]}`, isFooterInfo: true });
                }
                    setReportData(data);
                    setSelectedZone('all');
            })
        }
    }, [selectedRegion])

    useEffect(() => {
        if (isPageLoaded) {
            axiosClient.get(`api/Report/GetFieldReportData?regCode=${selectedRegion}&zone=${selectedZone}`).then((response) => {
                let data = response.data.data.dataSet.table;
                if (data && data.length > 0) {
                    data.push({ isFooterInfo: true });
                    data.push({ atdName: `Total Attendees:${data[0]["reccount"]}`, isFooterInfo: true });
                }
                    setReportData(data);
            })
        }
    }, [selectedZone])

    const getReportName = () => {
        if (reportList && reportList.length > 0) {
            return reportList[0].elements.report_list.linkedItems.find(r => r.elements.id.value == "3")?.elements.name.value;
        }
        else {
            return "";
        }
    }

    const onRegionChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setSelectedRegion(e.target.value);
        const selectedRegionDetail = regions.find(r => r?.regCode == e.target.value);
        if (selectedRegionDetail) {
            setSelectedRegionName(selectedRegionDetail.regDescription);
        }
    }

    const onZoneChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setSelectedZone(e.target.value)
    }

    const clearFilters = () => {
        setSelectedZone("all");
        setSelectedRegion("all");
        setSelectedRegionName("All");
    }

    const columns = [
        {
            Header: 'Attendee Name',
            accessor: 'atdName',
        },
        {
            Header: 'Title',
            accessor: 'jobTitle',
        },
        {
            Header: 'Region',
            accessor: 'region'
        },
        {
            Header: 'Zone',
            accessor: 'zone'
        },
        {
            Header: 'Enrollment status',
            accessor: 'enrlStatusDesc'
        },
        {
            Header: 'Enroll date',
            accessor: 'enrlDate'
        },
        {
            Header: 'Arrival date',
            accessor: 'arrivalDate'
        },
        {
            Header: 'Departure date',
            accessor: 'departuredate'
        }
    ]

    const userdetails = localStorage.getItem("nbcuserdetail");
    let isVirtual = false;
    if (userdetails) {
        isVirtual = (JSON.parse(userdetails)).programInfo?.isVirtual as boolean;
        if (isVirtual) {
            columns.push({ Header: "Virtual Attendee", accessor: "virtualAttendee" })
        }
    }

    return <div className="report-details">
        <h2 className="reports_heading">{getReportName()}</h2>
        <div className='form-group filtersection'>
            <select
                value={selectedRegion}
                onChange={onRegionChange}
                className='regionselect form-select'
            >
                <option value={"all"} >{"All regions"}</option>
                {regions.map((r: Region | undefined, index) => {
                    if (r)
                        return <option value={r.regCode} key={"region" + index}>{r.regDescription}</option>
                })}
            </select>
            <select
                value={selectedZone}
                onChange={onZoneChange}
                className='regionselect form-select marginleft'
            >
                <option value={"all"} >{"All zones"}</option>
                {filteredZones.map((r: Zone | undefined, index) => {
                    if (r)
                        return <option value={r.zone} key={"zone" + index}>{r.zoneName}</option>
                })}
            </select>
            <div className='clearbtndiv'>
                <button type='button' className='btn btn-secondary marginleft' onClick={clearFilters}>Clear</button>
            </div>
        </div>
        <ReactTable data={reportData} columns={columns} fileName={"Field Enrollment Report - " + selectedRegionName} sheetName={"Field Enrollment Report - " + selectedRegionName} />
        {reportData && reportData.length > 0 && <b>
            <label>Total Attendees&nbsp;: &nbsp;&nbsp;{reportData[0]["reccount"]}</label><br />
            {isVirtual &&
                <label>Total Virtual Attendees&nbsp;: &nbsp;&nbsp;{reportData[0]["virtualRecCount"]}</label>
            }
        </b>
        }
    </div>
}

export default FieldEnrollReport;