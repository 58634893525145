export const Tabs = [
    {id:"welcome",isPartOfScroll:true,navigateTo:"#welcome"},   
    {id:"confinfo",isPartOfScroll:true,navigateTo:"#confinfo"},
    {id:"agenda",isPartOfScroll:true,navigateTo:"#agenda"},
    {id:"attire",isPartOfScroll:true,navigateTo:"#attire"},
    {id:"faq",isPartOfScroll:true,navigateTo:"#faq"},
    {id:"eventarea",isPartOfScroll:true,navigateTo:"#eventarea"},
    {id:"linktoexpo",isPartOfScroll:true,navigateTo:"#linktoexpo"},
    {id:"enrollment",isPartOfScroll:false,navigateTo:"/nbc/enrollment"},
    {id:"reporting",isPartOfScroll:false,navigateTo:"/nbc/reporting"}
]