import React, { useEffect } from "react";
import { useState } from 'react';
import videoThumb from '../assets/images/subru/video-thumb.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import SignatureImage from '../assets/images/subru/JeffWalters_Sig.jpg'
import { HeaderTextTemplate, HeaderTextTemplateCollection, WelcomePage } from "../models";
import './Welcome.css';

type WelcomePageProps = {
    welcomePageData: WelcomePage
}
const Welcome: React.FC<WelcomePageProps> = ({ welcomePageData }) => {
    const [isShowMore, setIsShowMore] = useState(true);
    const [textToDisplay, setTextToDisplay] = useState("");
    const [moreText, setMoreText] = useState("");

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    useEffect(() => {
        if (welcomePageData?.elements?.letter?.value) {
            let displayText = welcomePageData.elements.letter.value.slice(0, 1230);
            let lastWordIndex = displayText.lastIndexOf(" ");
            setTextToDisplay(welcomePageData.elements.letter.value.slice(0, lastWordIndex));
            setMoreText(welcomePageData.elements.letter.value.slice(lastWordIndex, welcomePageData.elements.letter.value.length - 1));
        }
    }, [welcomePageData]);

    return (
        <>
            <div className="spacer bg-light team4">
                <div className="container">
                    <div className="row mt-2">
                        {welcomePageData && <>                           
                            <div className="col-md-6 col-lg-6">
                                <div className="text-box">
                                    <section className='note'
                                        dangerouslySetInnerHTML={{ __html: textToDisplay }}>
                                    </section>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 aos-init aos-animate" data-aos="flip-up" data-aos-duration="1200">
                                <iframe src={welcomePageData.elements.video_url.value + "#t=0.1"} height={250} width={300} className="welcomevideo" allowFullScreen></iframe>
                                <iframe src={welcomePageData.elements.second_video_url.value + "#t=0.1"} height={250} width={300} className="welcomevideo" allowFullScreen></iframe>
                            </div>
                        </>}
                    </div>
                    {!isShowMore &&
                        <div className="row">
                            <div className="text-box">
                                <section className='note'
                                    dangerouslySetInnerHTML={{ __html: moreText }}>
                                </section>
                            </div>
                        </div>}
                </div>
                <h5 className="font-medium " id="read-more-less">
                    <button className="btn btn-danger-gradiant btn-rounded btn-md btn-arrow text-white btn-read-more-less" onClick={toggleReadMoreLess}>
                        <span>{isShowMore ? "Show more" : "Show less"}<FontAwesomeIcon className="arrowicon" icon={faArrowRight} /></span>
                    </button>
                </h5>
            </div>
            <div className="bg-light spacer feature1">
                <div className="container">
                    {welcomePageData &&
                        <div className="row">
                            <div className="row justify-content-center">
                                <div className="col-lg-7 col-md-9 text-center">
                                    <h2 className="title">{welcomePageData.elements.title.value }</h2>
                                </div>
                            </div>
                        </div>}
                    <div className="row m-t-40 justify-content-center">
                        <div className="accordion accordion-flush" id="welcomenotesaccordion">
                            {welcomePageData && welcomePageData.elements.welcome_page_notes
                                && welcomePageData.elements.welcome_page_notes.linkedItems
                                && welcomePageData.elements.welcome_page_notes.linkedItems.map((w, index) => {
                                    return (

                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id={"welnote-flush-heading" + index}>
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#welnote-flush-collapse" + index} aria-expanded="false" aria-controls={"welnote-flush-collapse" + index}>
                                                    {w.elements.header.value}
                                                </button>
                                            </h2>
                                            <div id={"welnote-flush-collapse" + index} className="accordion-collapse collapse" aria-labelledby={"welnote-flush-heading" + index} data-bs-parent="#welcomenotesaccordion">

                                                <div className='accordion-body'
                                                    dangerouslySetInnerHTML={{ __html: w.elements.text.value }}>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}


export default Welcome;