import { Link } from 'react-router-dom';
import "./reports.css";
import { useEffect} from 'react';
import { Report} from '../../models';
import { useOutletContext } from "react-router-dom";
import { ReportContextData } from '../../layouts/reporting/ReportsLayout';
import { ReportsData } from '../../utils/constants';

export default function Reports() {
  const { data, setData,reportList } = useOutletContext<ReportContextData>();

  useEffect(() => {
    updateBreadcrumbData();
  }, []);

  const updateBreadcrumbData = () => {
    let contextData = JSON.parse(JSON.stringify(data));
    contextData.level = 0;
    setData(contextData,reportList);
  }

  const checkIfReportPresent = (id:string)=>{
    if(reportList && reportList.length>0 && reportList[0].elements.report_list && reportList[0].elements.report_list.linkedItems.some(r=>r.elements.id.value==id)){
      return {displayReport:true, reportDetail:reportList[0].elements.report_list.linkedItems.find(r=>r.elements.id.value==id)};
    }

    return {displayReport:false,reportDetail:null};
  }

  return (
    <>
      <h1 className="reports_heading">{reportList && reportList.length>0 && reportList[0].elements.title.value}</h1>
      <ul className='reports-list1'>
        {ReportsData.map((report,index) => {
          const {displayReport,reportDetail} = checkIfReportPresent(report.id.toString());
          if(displayReport){
            return <Link to={report.navigateto} key={"reportlist"+index} onClick={updateBreadcrumbData}>
            <li className='report-item'>{reportDetail?.elements.name.value}</li>
          </Link>
          }
          
        })}
      </ul>
    </>
  );
}