import React from "react";
import './HomeBanner.css'
import { HeaderData, HeaderLayoutDetail } from '../models';

type HomeBannerProps={
    bannerData:HeaderLayoutDetail[]
}
const HomeBanner : React.FC<HomeBannerProps> = ({ bannerData }) => {
    return (
        <div className="home-banner">
       {bannerData && <img src={bannerData[0].elements.logo_and_banner.linkedItems[0].elements.banner.value[0].url} alt="Header"  className="banner-image" />}
    </div>
    )
}

export default HomeBanner;