import React from "react";
import { LinkToExpoPage } from "../models";

type LinkToExpoPageProps = {
    linkToExpoPageData: LinkToExpoPage
}
const LinkToExpo: React.FC<LinkToExpoPageProps> = ({ linkToExpoPageData }) => {
    return (
        <>
            {linkToExpoPageData &&
                <>
                    <div className="spacer bg-light team4">
                        <div className="container">
                            <div className="row justify-content-center m-b-30">
                                <div className="col-lg-7 col-md-9 text-center">
                                    <h2 className="title">{linkToExpoPageData.elements.title.value}</h2>
                                </div>
                            </div>
                            <div className="row m-t-40 conference-info">
                                <h4>{linkToExpoPageData.elements.heading.value}</h4>
                                <p className="" dangerouslySetInnerHTML={{ __html: linkToExpoPageData.elements.content.value }} />
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default LinkToExpo;