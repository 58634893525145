import React, { useEffect, useState, useCallback, useRef, useContext } from "react";
import Welcome from "./Welcome";
import Attire from "./Attire";
import ConferenceInfo from "./ConferenceInfo";
import Agenda from "./Agenda";
import FAQ from "./FAQ";
import Tourism from "./Tourism";
import LinkToExpo from "./LinkToExpo";
import { deliveryClient } from "../api/cmsDeliveryClient";
import { useOutletContext } from "react-router-dom";
import { ContextData } from "./NBCLayout";
import { UserContext } from '../common/UserContext';
import { AgendaPage, HeaderLayoutDetail } from "../models";
import './NBCDetails.css'
import { Tabs } from "../utils/tabs";

type currentSectionDetail = {
  codename: string,
  updateStateWithData: Function
}

const NBCDetails = () => {
  const { setActiveTab, headerData, selectedTab, containerRef } = useOutletContext<ContextData>();
  const [welcomePageData, setWelcomePageData] = useState<any>(null);
  const [attirePageData, setAttirePageData] = useState<any>(null);
  const [agendaPageData, setAgendaPageData] = useState<any>(null);
  const [faqPageData, setFaqPageData] = useState<any>(null);
  const [linkToExpoPageData, setLinkToExpoPageData] = useState<any>(null);
  const [confInfoPageData, setCOnfInfoPageData] = useState<any>(null);
  const [tourismPageData, setTourismPageData] = useState<any>(null);
  const [displayLinkToExpo, setDisplayLinkToExpo] = useState(true);
  const sectionsRef = useRef<HTMLDivElement[]>([]);
  const userData = useContext(UserContext);

  useEffect(() => {
    // Gets three items by their codenames. The codenames are unique per project.
    deliveryClient.items()
      .depthParameter(2)
      .inFilter("system.type", ["welcome_page"])
      .toPromise()
      .then((response) => {
        const welcomeData = response.data.items.filter(w => w.system.type == "welcome_page");
        setWelcomePageData(welcomeData);
      })
  }, []);

  useEffect(() => {
    if (sectionsRef && sectionsRef.current) {
      sectionsRef.current.forEach((s) => {
        if (s.id == selectedTab) {
          s.scrollIntoView({ behavior: 'smooth' })
          setActiveTab(selectedTab);
          if (selectedTab == "welcome") {
            containerRef.current?.scrollIntoView({ behavior: 'smooth' })
          }
        }
      })
    }
  }, [selectedTab, linkToExpoPageData, tourismPageData, faqPageData])

  useEffect(() => {
    if (headerData) {
      let headerDetails = headerData as HeaderLayoutDetail[];
      let displayLnkToExpo = false;
      headerDetails.forEach((e) => {
        if (e.elements.tablist.linkedItems.some((t) => t.elements.id.value == "linktoexpo")) {
          displayLnkToExpo = true;
        }
      })
      setDisplayLinkToExpo(displayLnkToExpo);
    }
  }, [headerData])

  useEffect(() => {

    // Observer for activating tabs
    const options = {
      root: null,
      threshold: 0.3
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveTab(entry.target.id);
        }
      });
    }, options);

    sectionsRef.current.forEach((section) => {
      observer.observe(section);
    });

    // To disable the entire IntersectionObserver
    return () => {
      observer.disconnect();
    };
  },[]);

  useEffect(() => {
    Tabs.forEach((t) => {
      if (checkIfTabToDisplay(t.id)) {
        loadData(t.id);
      }
    })
  }, [headerData])

  const refCallback = useCallback((element: never) => {
    if (element) {
      sectionsRef.current.push(element);
    }
  },[]);

  const checkIfTabToDisplay = (tb: string) => {
    let isTabExist: boolean = false;
    if (headerData) {
      headerData.forEach((e: any) => {
        if (e.elements.tablist.linkedItems.some((a: any) => a.elements.id.value == tb)) {
          isTabExist = true;
          return;
        }
      });
    }
    return isTabExist;
  }

  const loadData = (sectionToLoadData: string) => {
    let sectionDetail: currentSectionDetail = {
      codename: "",
      updateStateWithData: () => { }
    }
    if (sectionToLoadData) {
      switch (sectionToLoadData.toLowerCase()) {
        case "welcome":
          sectionDetail.codename = "attire_page"
          sectionDetail.updateStateWithData = setAttirePageData;
          break;
        case "attire":
          sectionDetail.codename = "conference_information"
          sectionDetail.updateStateWithData = setCOnfInfoPageData;
          break;
        case "confinfo":
          sectionDetail.codename = "agenda_page"
          sectionDetail.updateStateWithData = setAgendaPageData;
          break;
        case "agenda":
          sectionDetail.codename = "faq_page"
          sectionDetail.updateStateWithData = setFaqPageData;
          break;
        case "faq":
          sectionDetail.codename = "tourism_page"
          sectionDetail.updateStateWithData = setTourismPageData;
          break;
        case "eventarea":
          sectionDetail.codename = "link_to_expo_page"
          sectionDetail.updateStateWithData = setLinkToExpoPageData;
          break;
      }
    }

    loadDataFromApi(sectionDetail);
  }

  const getPolicyCodeForConfInfo = () => {
    if (userData.userData && userData.userData.accessCode) {
      const userRegType = userData.userData.accessCode.toLowerCase();
      switch (userRegType) {
        case "corpdenali":
        case "fieldacadia":
        case "sbr":
        case "partnersequoia":
          return "corporatesub";
        case "inddistarches":
          return "inddist";
        case "vendorglacier":
        case "disteverglades":
          return "vendordistributor";
        default:
          return "general";
      }
    }
    return "general";
  }

  const getPolicyCodeForFAQ = () => {
    if (userData.userData && userData.userData.accessCode) {
      const userRegType = userData.userData.accessCode.toLowerCase();
      switch (userRegType) {
        case "corpdenali":
        case "fieldacadia":
        case "sbr":
        case "partnersequoia":
        case "inddistarches":
        case "vendorglacier":
        case "disteverglades":
          return "corporate";
        default:
          return "general";
      }
    }
    return "general";
  }

  const loadDataFromApi = (sectionDetail: currentSectionDetail) => {
    if (sectionDetail.codename == "agenda_page") {
      let code = "agenda_page_item";
      if (userData && userData.userData && userData.userData.isDealer) {
        code = "agenda_page_item_dealer";
      }

      deliveryClient.item<AgendaPage>(code)
        .depthParameter(2)
        .toPromise()
        .then(
          (response) => {
            sectionDetail.updateStateWithData(response.data.item);
          }
        )
    }
    else if (sectionDetail.codename == "conference_information") {
      let code = getPolicyCodeForConfInfo();
      deliveryClient.items()
        .depthParameter(2)
        .containsFilter("elements.reggroup", [code])
        .toPromise()
        .then((response) => {
          sectionDetail.updateStateWithData(response.data.items[0]);
        })
    }
    else if (sectionDetail.codename == "faq_page") {
      let code = getPolicyCodeForFAQ();
      deliveryClient.items()
        .depthParameter(2)
        .containsFilter("elements.reggroupfaq", [code])
        .toPromise()
        .then((response) => {
          sectionDetail.updateStateWithData(response.data.items[0]);
        })
    }
    else {
      deliveryClient.items()
        .depthParameter(2)
        .inFilter("system.type", [sectionDetail.codename])
        .toPromise()
        .then((response) => {
          sectionDetail.updateStateWithData(response.data.items[0]);
        })
    }
  }

  return <>
    <section id="welcome" ref={refCallback}>
      <Welcome welcomePageData={welcomePageData ? welcomePageData[0] : null} />
    </section>
    <section id="confinfo" ref={refCallback}>
      <ConferenceInfo conferenceInfoPageData={confInfoPageData} />
    </section>
    <section id="agenda" ref={refCallback}>
      <Agenda agendaPageData={agendaPageData} />
    </section>
    <section id="attire" ref={refCallback}>
      <Attire attirePageData={attirePageData}></Attire>
    </section>
    <section id="faq" ref={refCallback}>
      <FAQ faqPageData={faqPageData} />
    </section>
    <section id="eventarea" ref={refCallback}>
      <Tourism tourismPageData={tourismPageData} title={"Nashville area"} />
    </section>
    <section id="linktoexpo" className={`${displayLinkToExpo ? "" : "hidelinktoexpo"}`} ref={refCallback}>
      <LinkToExpo linkToExpoPageData={linkToExpoPageData} />
    </section>
  </>
}

export default NBCDetails;