import { useEffect, useState } from "react";
import { useLoaderData, useNavigate, useOutletContext, useParams } from "react-router-dom"
import { ZoneTable } from "../../components/Tables/ZoneTable"
import axiosClient from "../../api/axios";
import { ReportContextData } from "../../layouts/reporting/ReportsLayout";
import "./reports.css"

export default function ZoneDetail() {
    const navigate = useNavigate();
    const { data, setData, reportList } = useOutletContext<ReportContextData>();
    const [reportData, setReportData] = useState({})
    const [exportData,setExportData] = useState([])
    const { region } = useParams();
    useEffect(() => {
        let existingContextData = JSON.parse(JSON.stringify(data));
        existingContextData.level = 2;
        setData(existingContextData, reportList);

        let dataForApi = {
            region: region,
            zone: "",
            district: "",
            select: ""
        }
        axiosClient.post("api/Report/GetEnrollments?reportLevel=Region", dataForApi).then((res) => {

            res.data.data.dataSet.table.forEach((element: any) => {
                element.region = region;
            });
            setReportData(res.data.data.dataSet.table);
        })

        axiosClient.post("api/Report/GetStoreData?reportLevel=Region", dataForApi).then((response) => {
            let existingContextData = JSON.parse(JSON.stringify(data));
            let dataToExport = response.data.data.dataSet.table;
            if (dataToExport && dataToExport.length > 0) {
                dataToExport.push({isFooterInfo:true});
                dataToExport.push({ dlrnum: 'Region:',['dealer Name']:existingContextData.regionName, isFooterInfo:true });
            }
            setExportData(dataToExport)
        });

    }, [])

    const updateBreadcrumbData = (zoneCode: string, zoneName: string) => {
        let existingContextData = JSON.parse(JSON.stringify(data));
        existingContextData.level = 3;
        existingContextData.zoneCode = zoneCode;
        existingContextData.zoneName = zoneName;
        setData(existingContextData, reportList);
    }

    return (
        <div className="report-details">
            <h2 className="reports_heading">STORE ENROLLMENT REPORT BY ZONE</h2>
            <ZoneTable region={reportData} exportData = {exportData} updateBreadcrumbData={updateBreadcrumbData} />
        </div>
    )
}