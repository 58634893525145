import { useNavigate, useOutletContext, useParams } from "react-router-dom"
import { DetailTable } from "../../components/Tables/DetailTable";
import axiosClient from "../../api/axios";
import "./reports.css"
import { ChangeEvent, useEffect, useState } from "react";
import { DetailReportFilterOptions } from "../../utils/constants";
import { ReportContextData } from "../../layouts/reporting/ReportsLayout";

export default function Details() {
    const [selectedFilter, setSelectedFilter] = useState("ALL");
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const [isVirtual, setIsVirtual] = useState(false);
    const navigate = useNavigate();
    const { region, zone, id } = useParams()
    const [reportData, setReportData] = useState([]);
    const { data } = useOutletContext<ReportContextData>();

    useEffect(() => {
        setIsPageLoaded(true);
        const userdetails = localStorage.getItem("nbcuserdetail");
        let isVirtual = false;
        if (userdetails) {
            isVirtual = (JSON.parse(userdetails)).programInfo?.isVirtual as boolean;
            setIsVirtual(isVirtual);
        }
        let dataForApi = {
            region: region,
            zone: zone,
            district: id,
            select: "ALL" // E, "ALL", "N"
        }
        axiosClient.post("api/Report/GetEnrollments?reportLevel=District", dataForApi).then((res) => {
            let dataToDisplay = res.data.data.dataSet.table;
            let existingContextData = JSON.parse(JSON.stringify(data));
            if (dataToDisplay && dataToDisplay.length > 0) {
                dataToDisplay.push({ isFooterInfo: true });
                dataToDisplay.push({ dlrnum: 'Total Attendee:', ['dealer Name']: (dataToDisplay[0]["enrollAtdFree"] + dataToDisplay[0]["enrollAtdPay"]), isFooterInfo: true });

                if (isVirtual) {
                    dataToDisplay.push({ dlrnum: 'Total Virtual Attendee Free:', ['dealer Name']: dataToDisplay[0]["virtualAttendeeFree"], isFooterInfo: true });
                    dataToDisplay.push({ dlrnum: 'Total Virtual Attendee Paid:', ['dealer Name']: dataToDisplay[0]["virtualAttendeePay"], isFooterInfo: true });
                }

                dataToDisplay.push({ dlrnum: 'Total Stores Enrolled:', ['dealer Name']: dataToDisplay[0]["enrollDealer"], isFooterInfo: true });
                dataToDisplay.push({ dlrnum: 'Total Stores Not Enrolled:', ['dealer Name']: dataToDisplay[0]["notEnrollDealer"], isFooterInfo: true });
                dataToDisplay.push({ dlrnum: 'District:', ['dealer Name']: existingContextData.districtName, isFooterInfo: true });
                dataToDisplay.push({ dlrnum: 'Zone:', ['dealer Name']: existingContextData.zoneName, isFooterInfo: true });
                dataToDisplay.push({ dlrnum: 'Region:', ['dealer Name']: existingContextData.regionName, isFooterInfo: true });
            }
            setReportData(dataToDisplay);
        });

        return (() => setSelectedFilter("ALL"))
    }, [])

    useEffect(() => {
        if (isPageLoaded) {
            let dataForApi = {
                region: region,
                zone: zone,
                district: id,
                select: selectedFilter
            }
            axiosClient.post("api/Report/GetEnrollments?reportLevel=District", dataForApi).then((response) => {
                let dataToDisplay = response.data.data.dataSet.table;
                let existingContextData = JSON.parse(JSON.stringify(data));
                if (dataToDisplay && dataToDisplay.length > 0) {
                    dataToDisplay.push({ isFooterInfo: true });
                    dataToDisplay.push({ dlrnum: 'Total Attendee:', ['dealer Name']: (dataToDisplay[0]["enrollAtdFree"] + dataToDisplay[0]["enrollAtdPay"]), isFooterInfo: true });

                    if (isVirtual) {
                        dataToDisplay.push({ dlrnum: 'Total Virtual Attendee Free:', ['dealer Name']: dataToDisplay[0]["virtualAttendeeFree"], isFooterInfo: true });
                        dataToDisplay.push({ dlrnum: 'Total Virtual Attendee Paid:', ['dealer Name']: dataToDisplay[0]["virtualAttendeePay"], isFooterInfo: true });
                    }

                    dataToDisplay.push({ dlrnum: 'Total Stores Enrolled:', ['dealer Name']: dataToDisplay[0]["enrollDealer"], isFooterInfo: true });
                    dataToDisplay.push({ dlrnum: 'Total Stores Not Enrolled:', ['dealer Name']: dataToDisplay[0]["notEnrollDealer"], isFooterInfo: true });
                    dataToDisplay.push({ dlrnum: 'District:', ['dealer Name']: existingContextData.districtName, isFooterInfo: true });
                    dataToDisplay.push({ dlrnum: 'Zone:', ['dealer Name']: existingContextData.zoneName, isFooterInfo: true });
                    dataToDisplay.push({ dlrnum: 'Region:', ['dealer Name']: existingContextData.regionName, isFooterInfo: true });
                }
                setReportData(dataToDisplay);
            });
        }
    }, [selectedFilter])

    const onFilterChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setSelectedFilter(e.target.value);
    }

    return (
        <div className="report-details">
            <h2 className="reports_heading">STORE ENROLLMENT DETAIL</h2>
            <div>
                <select
                    value={selectedFilter}
                    onChange={onFilterChange}
                    className='regionselect form-select'
                >
                    {DetailReportFilterOptions.map((r, index) => {
                        if (r)
                            return <option value={r.value} key={"distdetail" + index}>{r.label}</option>
                    })}
                </select>
            </div>
            <DetailTable region={reportData} isVirtual={isVirtual} />
            {reportData && reportData.length > 0 &&
                <b>
                    <label>Total Attendee: {reportData[0]["enrollAtdFree"] + reportData[0]["enrollAtdPay"]}</label><br/>
                    {isVirtual && <>
                        <label>Total Virtual Attendee Free: {reportData[0]["virtualAttendeeFree"]}</label><br />
                        <label>Total Virtual Attendee Paid: {reportData[0]["virtualAttendeePay"]}</label><br />
                    </>}
                    <label>Total Stores Enrolled: {reportData[0]["enrollDealer"]}</label><br />
                    <label>Total Stores Not Enrolled: {reportData[0]["notEnrollDealer"]}</label><br />
                </b>
            }
        </div>
    )
}