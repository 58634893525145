import React, { RefObject, useEffect, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { deliveryClient } from "../api/cmsDeliveryClient";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { HeaderData, HeaderLayoutDetail } from "../models";
import HomeBanner from "./HomeBanner";
import { getHeaderCMSCodes } from "../utils/utilityfunctions";

export type ContextData = {
  headerData: any,
  setActiveTab: Function,
  selectedTab: string,
  containerRef: RefObject<HTMLDivElement>
};


const NBCLayout = () => {
  const [visibleSection, setVisibleSection] = useState<string>("welcome");
  let containerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const updateActiveTab = (tab: string) => {
    setVisibleSection(tab);
  }
  const [contextDt, setContextDt] = useState<ContextData>({
    headerData: null,
    setActiveTab: updateActiveTab,
    selectedTab: "welcome",
    containerRef: containerRef
  });

  useEffect(() => {
    const userdetails = localStorage.getItem("nbcuserdetail");
    if (!userdetails) {
      navigate("/");
    }
    else {
      // Gets items by policy codenames.
      deliveryClient.items()
        .anyFilter("elements.user_access__access_code", getHeaderCMSCodes())
        .toPromise()
        .then(
          response => {
            const updatedHeaderData: ContextData = {
              headerData: response.data.items.filter((i) => i.system.type == "header_layout_detail") as HeaderLayoutDetail[],
              setActiveTab: updateActiveTab,
              selectedTab: "welcome",
              containerRef: containerRef
            }

            setContextDt(updatedHeaderData);
          }
        )
    }
  }, []);

  const updateSelectedTab = (tab: string) => {
    const updatedHeaderData: ContextData = {
      headerData: contextDt.headerData,
      setActiveTab: updateActiveTab,
      selectedTab: tab,
      containerRef: containerRef
    }

    setContextDt(updatedHeaderData);
  }

  return (
    <>
      <div id="main-wrapper" ref={containerRef}>
        <Navbar navBarData={contextDt.headerData} activetab={visibleSection} updateSelectedTab={updateSelectedTab} />
        <div className="page-wrapper">
          <div className="container-fluid">
            {visibleSection != "reporting" &&
              <HomeBanner bannerData={contextDt.headerData} />}
            <Outlet context={contextDt} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default NBCLayout;