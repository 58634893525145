import React, { useEffect, useState } from 'react'
import { deliveryClient } from "../api/cmsDeliveryClient";
import { EnrollmentRegType } from '../models';

type EnrollVirtualProps = {
    setIsVirtualReg: Function
}

const EnrollVirtual: React.FC<EnrollVirtualProps> = ({ setIsVirtualReg }) => {
    const [content, setContent] = useState<Partial<EnrollmentRegType>>({})
    const [selectedOption, setSelectedOption] = useState("Physical");

    useEffect(() => {
        deliveryClient.item<EnrollmentRegType>('enrollment_reg_type_item')
            .toPromise()
            .then(
                response => {
                    setContent(response.data.item);
                }
            )
    }, [])

    const handleChange = (e: any) => {
        setSelectedOption(e.target.value);
        setIsVirtualReg(e.target.value==='Virtual');
    }
    return (
        <div className='row virtualattend'>
            {content && content.elements && <>
                <span><b>Note:</b> <p dangerouslySetInnerHTML={{ __html: content.elements.note.value }} /></span>
                <label>
                    <input type="radio" value="Physical" checked={selectedOption === 'Physical'} onChange={handleChange} />
                    {content.elements.first_radio_btn_txt.value}
                </label>
                <label>
                    <input type="radio" value="Virtual" checked={selectedOption === 'Virtual'} onChange={handleChange} />
                    {content.elements.second_radio_btn_txt.value}
                </label>
            </>
            }
        </div>
    )
}

export default EnrollVirtual;