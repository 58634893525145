import { useMemo } from 'react'
import { Column, useTable } from 'react-table'
import { Link } from 'react-router-dom'
import { addTotalRow} from '../../utils/utilityfunctions'
import { ReactTable } from '../../common/ReactTable'
import { ReactTableMultiExport } from '../../common/ReactTableMultiExport'

export const ZoneTable = ({ region, updateBreadcrumbData, exportData }: any) => {
    const updateBreadcrumb = (rowData: any) => {
        updateBreadcrumbData(rowData.cell.row.original["zonecode"], rowData.cell.row.original["zonename"])
    }
    const ZONE_COLUMNS = [
        {
            Header: 'Zone',
            accessor: 'zonename',

            Cell: (props: any) => props.cell.row.original.displayNonLink ? props.value : <Link to={`/nbc/reporting/region/${props.cell.row.original.region}/zone/${props.cell.row.original.zonecode}/district`} onClick={() => updateBreadcrumb(props)}>{props.value} </Link>
        },
        {
            Header: 'Total stores number',
            accessor: 'totDealer'
        },
        {
            Header: 'Stores enrolled number',
            accessor: 'enrollDealer'
        },
        {
            Header: 'Percent',
            accessor: 'pctEnrollDlr',
            displayAverage: true
        },
        {
            Header: 'Stores not enrolled number',
            accessor: 'notEnrolled'
        },
        {
            Header: 'Percent',
            accessor: 'pctnotenrolled',
            displayAverage: true
        },
        {
            Header: 'Stores to reach objective number',
            accessor: 'objreq'
        },
        {
            Header: 'Percent',
            accessor: 'fixPctObjReq',
            displayAverage: true
        },
        {
            Header: 'Stores remaining to reach objective number',
            accessor: 'objCount'
        }
    ]

    const DETAIL_COLUMNS_EXPORT = [
        {
            Header: 'Zone',
            accessor: 'zoneName',
        },
        {
            Header: 'District',
            accessor: 'districtName'
        },
        {
            Header: 'Store code',
            accessor: 'dlrnum',
        },
        {
            Header: 'Store name',
            accessor: 'dealer Name'
        },
        {
            Header: 'Store city/state',
            accessor: 'cityState'
        },
        {
            Header: 'Enrollment status',
            accessor: 'enrlStatusDesc'
        },
        {
            Header: 'Attendee enroll date',
            accessor: 'enrlDate'
        },
        {
            Header: 'Attendee name',
            accessor: 'atdName'
        }
    ]

    const columns = useMemo<Column<any>[]>(() => ZONE_COLUMNS, [])
    const dataForReportNew = addTotalRow(columns, region);
    const exportcolumns = useMemo<Column<any>[]>(() => DETAIL_COLUMNS_EXPORT, [])

    const enrolledStores = exportData ? exportData.filter((d: any) => (d.loginid != null && d.loginid != "") || d.isFooterInfo) : [];
    const notEnrolledStores = exportData ? exportData.filter((d: any) => d.loginid == null || d.loginid == "") : [];

    return (
        <>
            {dataForReportNew && dataForReportNew.length > 0 &&
                <ReactTableMultiExport
                    data={dataForReportNew}
                    columns={columns}
                    allStores={exportData}
                    enrolledStores={enrolledStores}
                    exportColumns={exportcolumns}
                    notEnrolledStores={notEnrolledStores}
                    fileName="Store Enrollment by Zone" sheetName={"Store Enrollment by Zone"} />}
        </>
    )
}