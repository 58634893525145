import React, { useEffect, useState } from 'react'
import axiosClient from "../../api/axios";
import { useOutletContext } from 'react-router-dom';
import { ReactTable } from '../../common/ReactTable';
import { ReportContextData } from '../../layouts/reporting/ReportsLayout';

const TravellerReport = () => {
    const { reportList } = useOutletContext<ReportContextData>();
    const [reportData, setReportData] = useState([]);

    useEffect(() => {
        axiosClient.get("api/Report/GetReportAtendeeCountByType").then((res) => {
            setReportData(res.data.data.dataSet.table);
        });
    }, [])

    const getReportName = () => {
        if (reportList && reportList.length > 0) {
            return reportList[0].elements.report_list.linkedItems.find(r => r.elements.id.value == "7")?.elements.name.value;
        }
        else {
            return "";
        }
    }

    const columns = [
        {
            Header: 'Traveler type',
            accessor: 'attendeedesc',
        },
        {
            Header: 'Total enrollments',
            accessor: 'totalenrolledAttendees'
        }
    ]
    return <div className="report-details">
        <h2 className="reports_heading">{getReportName()}</h2>
        <ReactTable data={reportData} columns={columns} fileName={"Enrollment by Traveler Type Report"} sheetName={"Enrollment by Traveller Report"}/>
    </div>
}

export default TravellerReport;