import { useLoaderData, useNavigate, useOutletContext } from "react-router-dom"
import { RegionTable } from "../../components/Tables/RegionTable";
import axiosClient from "../../api/axios";
import "./reports.css"
import { ReportContextData } from "../../layouts/reporting/ReportsLayout";
import { useEffect, useState } from "react";

export default function RegionDetails() {
    const navigate = useNavigate();
    const { data, setData, reportList } = useOutletContext<ReportContextData>();
    const [reportData, setReportData] = useState({})


    useEffect(() => {
        let existingContextData = JSON.parse(JSON.stringify(data));
        existingContextData.level = 1;
        setData(existingContextData, reportList);

        let dataForApi = {
            region: "",
            zone: "",
            district: "",
            select: ""
        }
        axiosClient.post("api/Report/GetEnrollments?reportLevel=All", dataForApi).then((response) => {
            setReportData(response.data.data.dataSet.table)
        });      
    }, [])

    const updateBreadcrumbData = (regCode: string, regName: string) => {
        let existingContextData = JSON.parse(JSON.stringify(data));
        existingContextData.level = 2;
        existingContextData.regionCode = regCode;
        existingContextData.regionName = regName;
        setData(existingContextData, reportList);
    }

    return (
        <div className="report-details">
            <h2 className="reports_heading">STORE ENROLLMENT REPORT BY REGION</h2>
            <RegionTable region={reportData} updateBreadcrumbData={updateBreadcrumbData} />
        </div>
    )
}
