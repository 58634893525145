import React, { useEffect, useState } from 'react'
import axiosClient from "../../api/axios";
import { useOutletContext } from 'react-router-dom';
import { ReactTable } from '../../common/ReactTable';
import { ReportContextData } from '../../layouts/reporting/ReportsLayout';

const CandidateReport = () => {
    const { reportList } = useOutletContext<ReportContextData>();
    const [reportData, setReportData] = useState([]);

    useEffect(() => {
        axiosClient.get("api/Report/GetEnrollmentReportsByType?regCode=010&rptType=5").then((res) => {
            let data = res.data.data.dataSet.table;
            if (data && data.length > 0) {
                data.push({ isFooterInfo: true });
                data.push({ atdName: `Total Attendees:${data[0]["reccount"]}`, isFooterInfo: true });
            }
            setReportData(data);
        });
    }, [])

    const getReportName = () => {
        if (reportList && reportList.length > 0) {
            return reportList[0].elements.report_list.linkedItems.find(r => r.elements.id.value == "2")?.elements.name.value;
        }
        else {
            return "";
        }
    }

    const columns = [
        {
            Header: 'Attendee Name',
            accessor: 'atdName',
        },
        {
            Header: 'Region',
            accessor: 'region'
        },
        {
            Header: 'Store name',
            accessor: 'busName'
        },
        {
            Header: 'Store city/state',
            accessor: 'busCityState'
        },
        {
            Header: 'Enrollment status',
            accessor: 'enrlStatusDesc'
        },
        {
            Header: 'Enroll date',
            accessor: 'enrlDate'
        }
    ]

    const userdetails = localStorage.getItem("nbcuserdetail");
    let isVirtual = false;
    if (userdetails) {
        isVirtual = (JSON.parse(userdetails)).programInfo?.isVirtual as boolean;
        if (isVirtual) {
            columns.push({ Header: "Virtual Attendee", accessor: "virtualAttendee" })
        }
    }

    return <div className="report-details">
        <h2 className="reports_heading">{getReportName()}</h2>
        <ReactTable data={reportData} columns={columns} fileName={"Store Candidate Enroll Report"} sheetName={"Store Candidate Enroll Report"} />
        {reportData && reportData.length > 0 && <b>
            <label>Total Attendees&nbsp;: &nbsp;&nbsp;{reportData[0]["reccount"]}</label><br />
            {isVirtual &&
                <label>Total Virtual Attendees&nbsp;: &nbsp;&nbsp;{reportData[0]["virtualRecCount"]}</label>
            }
        </b>
        }
    </div>
}

export default CandidateReport;