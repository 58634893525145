import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { deliveryClient } from "../../api/cmsDeliveryClient";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import "./reporting.css";
import Breadcrumb from "../../pages/reports/Breadcrumb";
import { BreadcrumbData } from "../../models/BreadcrumbData";
import { getHeaderCMSCodes } from "../../utils/utilityfunctions";
import { ReportsTypes } from "../../models";
import { ContextData } from "../../components/NBCLayout";


export type ReportContextData = {
  data: BreadcrumbData,
  reportList: ReportsTypes[]
  setData: Function
}

export default function ReportsLayout() {
  const [headerData, setHeaderData] = useState<any>(null);
  const visibleSection = "reporting"
  const navigate = useNavigate();
  const location = useLocation();
  const { setActiveTab } = useOutletContext<ContextData>();
  let bData: BreadcrumbData = { level: 0, regionCode: "", regionName: "", zoneCode: "", zoneName: "", districtCode: "", districtName: "" };
  const updateBreadcrumbData = (brData: BreadcrumbData, report_list: ReportsTypes[]) => {
    setContextDt({
      data: brData,
      reportList: report_list,
      setData: updateBreadcrumbData
    })
  }
  const [contextDt, setContextDt] = useState<ReportContextData>({
    data: bData,
    reportList: [],
    setData: updateBreadcrumbData
  });

  useEffect(() => {
    setActiveTab("reporting");
    const userdetails = localStorage.getItem("nbcuserdetail");
    if (!userdetails) {
      navigate("/");
    }
    else {
      // Gets items by policy codenames.
      deliveryClient.items()
        .anyFilter("elements.user_access__access_code", getHeaderCMSCodes())
        .toPromise()
        .then(
          response => {
            setHeaderData(response.data.items.filter((i) => i.system.type == "header_layout_detail"))
            setContextDt({
              data: contextDt.data,
              reportList: response.data.items.filter((i) => i.system.type == "reports_types") as ReportsTypes[],
              setData: updateBreadcrumbData
            })
          }
        )
    }
  }, []);

  return (
    <div className="container reportcontainer">
      <Breadcrumb breadCrumbData={contextDt} />
      <div className="row">
        <Outlet context={contextDt} />
      </div>
      {location.pathname != "/nbc/reporting" &&
        <button className="mt-3 mb-3 btn btn-secondary backbutton" onClick={() => navigate(-1)}>Back</button>
      }
    </div>
  )
}