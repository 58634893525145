import { useMemo } from 'react'
import { Column, useTable } from 'react-table'
import { Link } from 'react-router-dom'
import { addTotalRow} from '../../utils/utilityfunctions'
import { ReactTable } from '../../common/ReactTable'
import { ReactTableMultiExport } from '../../common/ReactTableMultiExport'

export const DistrictTable = ({ region, updateBreadcrumbData, exportData }: any) => {
    const updateBreadcrumb = (rowData: any) => {
        updateBreadcrumbData(rowData.cell.row.original["district"], rowData.cell.row.original["districtname"])
    }
    const DISTRICT_COLUMNS = [
        {
            Header: 'District',
            accessor: 'districtname',

            Cell: (props: any) => props.cell.row.original.displayNonLink ? props.value : <Link to={`/nbc/reporting/region/${props.cell.row.original.region}/zone/${props.cell.row.original.zone}/district/${props.cell.row.original.district}/`}
                onClick={() => updateBreadcrumb(props)}>{props.value} </Link>
        },
        {
            Header: 'Total stores number',
            accessor: 'totDealer'
        },
        {
            Header: 'Stores enrolled number',
            accessor: 'enrollDealer'
        },
        {
            Header: 'Percent',
            accessor: 'pctEnrollDlr',
            displayAverage: true
        },
        {
            Header: 'Stores not enrolled number',
            accessor: 'notEnrolled'
        },
        {
            Header: 'Percent',
            accessor: 'pctnotenrolled',
            displayAverage: true
        },
        {
            Header: 'Stores to reach objective number',
            accessor: 'objreq'
        },
        {
            Header: 'Percent',
            accessor: 'fixPctObjReq',
            displayAverage: true
        },
        {
            Header: 'Stores remaining to reach objective number',
            accessor: 'objCount'
        }
    ]

    const DETAIL_COLUMNS_EXPORT = [
        {
            Header: 'District',
            accessor: 'districtname'
        },
        {
            Header: 'Store code',
            accessor: 'dlrnum',
        },
        {
            Header: 'Store name',
            accessor: 'dealer Name'
        },
        {
            Header: 'Store city/state',
            accessor: 'cityState'
        },
        {
            Header: 'Enrollment status',
            accessor: 'enrlStatusDesc'
        },
        {
            Header: 'Attendee enroll date',
            accessor: 'enrlDate'
        },
        {
            Header: 'Attendee name',
            accessor: 'atdName'
        }
    ]

    const columns = useMemo<Column<any>[]>(() => DISTRICT_COLUMNS, [])
    const dataForReportNew = addTotalRow(columns, region);
    const exportcolumns = useMemo<Column<any>[]>(() => DETAIL_COLUMNS_EXPORT, [])

    const enrolledStores = exportData ? exportData.filter((d: any) => (d.loginid != null && d.loginid != "") || d.isFooterInfo) : [];
    const notEnrolledStores = exportData ? exportData.filter((d: any) => d.loginid == null || d.loginid == "") : [];

    return (
        <>
            {dataForReportNew && dataForReportNew.length > 0 &&
             <ReactTableMultiExport
             data={dataForReportNew}
             columns={columns}
             allStores={exportData}
             enrolledStores={enrolledStores}
             exportColumns={exportcolumns}
             notEnrolledStores={notEnrolledStores}
             fileName="Store Enrollment by District" sheetName={"Store Enrollment by District"} />}
        </>
    )
}