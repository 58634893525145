export const getHeaderCMSCodes = () => {
  const userdetails = localStorage.getItem("nbcuserdetail");
  const userFromLocalStorage = JSON.parse(userdetails);
  let codes = [];
  if (userFromLocalStorage.authorizationPolicy) {
    userFromLocalStorage.authorizationPolicy.forEach((a) => {
      switch (a) {
        case "general":
          codes.push("navigatingcorp");
          break;
        case "expo":
          codes.push("retailers");
          break;
        case "field":
          codes.push("field");
          break;
        case "corporate":
          codes.push("corporate");
          break;
      }
    })
  }
  return codes;
}

export const addTotalRow = (columns, data) => {
  let dataToModify = JSON.parse(JSON.stringify(data));
  if (dataToModify && dataToModify.length > 0) {
    let colName = columns[0]["accessor"];
    let grandTotalRecord = dataToModify.find((gt)=>gt[colName].toLowerCase()=="zzz");
    grandTotalRecord.displayNonLink = true;
    grandTotalRecord[colName] = "Grand Total";
    return dataToModify;
  }
}