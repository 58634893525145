import React from "react";
import { AgendaLinkPDF, AgendaPage } from "../models";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import "./Agenda.css"

type AgendaPageProps = {
    agendaPageData: AgendaPage
}
const Agenda: React.FC<AgendaPageProps> = ({ agendaPageData }) => {

    return (
        <>
            {agendaPageData &&
                <div className="spacer">
                    <div className="spacer">
                        <div className="container">
                            <div className="row mb-4">
                                <div className="row justify-content-center">
                                    <div className="col-lg-7 col-md-9 text-center">
                                        <h2 className="title">{agendaPageData.elements.title.value}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion accordion-flush" id="agendaaccordion">
                                {agendaPageData && agendaPageData.elements.links_and_pdfs.linkedItems.map((ap, index) => {
                                    return <div className="accordion-item">
                                        <h2 className="accordion-header acc-header" id={"agenda-acc-flush-heading" + index}>
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#agenda-acc-flush-collapse" + index} aria-expanded="false" aria-controls={"agenda-acc-flush-collapse" + index}>
                                                {ap.elements.link_text.value} -
                                                <span className="agenda-pdf">
                                                    {ap.elements.header.value}
                                                    <a href={ap?.elements?.pdf?.value[0].url} target="_blank" onClick={(e)=>e.stopPropagation()}>
                                                        <span>
                                                            <FontAwesomeIcon className="pdf-icon ml-3" icon={faFilePdf} />
                                                            Click here to download pdf
                                                        </span>
                                                    </a>
                                                </span>
                                            </button>

                                        </h2>
                                        <div id={"agenda-acc-flush-collapse" + index} className="accordion-collapse collapse" aria-labelledby={"agenda-acc-flush-heading" + index} data-bs-parent="#agendaaccordion">
                                            <div className='accordion-body row'>
                                                {ap.elements.datewise_agenda && ap.elements.datewise_agenda.linkedItems.map((da, i) => {
                                                    return <>
                                                        <div className="card col-md-3" data-aos="fade-up" data-aos-duration="1200">
                                                            <div className="card-header bg-info text-white">
                                                                {da.elements.header_date.value}
                                                            </div>
                                                            <div className="card-body text-left">
                                                                <p dangerouslySetInnerHTML={{ __html: da.elements.content.value }} />
                                                            </div>
                                                        </div>
                                                    </>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Agenda;