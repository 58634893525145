import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosClient from '../api/axios';
import { HeaderData, Login, PageLayout } from '../models';
import './LoginPage.css';
import Footer from './Footer';
import { UserContext } from '../common/UserContext';
import { UserData } from '../models/User';

type LoginProps = {
    loginPageData: Login,
    headerPageData: PageLayout
}
const LoginPage: React.FC<LoginProps> = ({ loginPageData, headerPageData }) => {
    const [userName, setUserName] = useState("");
    const [accessCode, setAccessCode] = useState("");
    const [showValidationError, setShowValidationError] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const { setUserData } = useContext(UserContext);

    const navigate = useNavigate();

    useEffect(() => {
        setShowValidationError(false);
        setErrors([]);
        return (() => {
            setShowValidationError(false);
            setErrors([]);
        })
    }, [])

    useEffect(() => {
        validateLogin();
    }, [userName, accessCode])

    const validateLogin = () => {
        setShowValidationError(false);
        setErrors([]);
        let valErrors = [];
        if (loginPageData && loginPageData.elements) {
            if (!userName && !accessCode) {
                valErrors.push(loginPageData.elements.first_text_box_required_error_message.value);
                valErrors.push(loginPageData.elements.second_txtbox_required_error_message.value);
                return valErrors;
            }

            if (!userName) {
                valErrors.push(loginPageData.elements.first_text_box_required_error_message.value);
            }

            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            if (userName && !emailRegex.test(userName)) {
                valErrors.push(loginPageData.elements.first_text_box_invalid_data_message.value);
            }

            if (!accessCode) {
                valErrors.push(loginPageData.elements.second_txtbox_required_error_message.value);
            }
        }

        return valErrors;
    }

    const login = () => {
        setErrors([]);
        let valErrors = validateLogin();
        if (valErrors && valErrors.length > 0) {
            setShowValidationError(true);
            setErrors(valErrors);
            return;
        }
        let dataForApi = {
            userName: userName,
            password: accessCode
        }
        axiosClient.post("User/ProcessLogin", dataForApi).then((response) => {
            if (response?.data?.succeeded) {
                let apiResponse = response.data?.data;
                if(apiResponse?.dealerInfo?.isDealer && !apiResponse?.authorizationPolicy){
                    apiResponse.authorizationPolicy = ["general"];
                }
                let userDataFromApi:UserData={
                    isDealer: apiResponse?.dealerInfo?.isDealer,
                    executiveManager: apiResponse?.dealerInfo?.executiveManager,
                    generalManager:apiResponse?.dealerInfo?.generalManager,
                    authorizationPolicy:apiResponse?.authorizationPolicy,
                    accessCode:dataForApi?.password
                }
                setUserData(userDataFromApi);
                localStorage.setItem("nbcuserdetail", JSON.stringify(apiResponse));
                navigate("/nbc/welcome");
            } else {
                setShowValidationError(true);
                setErrors([loginPageData.elements.second_txtbox_invalid_data_message.value]);
            }
        }).catch(error => {
            setShowValidationError(true);
            setErrors(["Unexpected error. Please try again"]);
        })
    }

    return (
        <>
            {loginPageData && headerPageData && (
                <div id="main-wrapper">
                    <section className="header" data-kontent-item-id={headerPageData.system.id}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-12 text-center">
                                    <img
                                        className="logo"
                                        src={headerPageData.elements.logo.value[0].url}
                                        alt={headerPageData.elements.logo.value[0]?.description ||
                                            headerPageData.elements.logo.value[0]?.name}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="welcome">
                        <div className="container-fluid">
                            <div className='row'>
                                <img className='welcome-banner'
                                    src={headerPageData.elements.banner.value[0].url}
                                    alt={headerPageData.elements.banner.value[0]?.description ||
                                        headerPageData.elements.banner.value[0]?.name}
                                />
                            </div>
                        </div>
                    </section>
                    <section className='login pt-3 pb-2'>
                        <div className="container">
                            <form>
                                <h3 className="login-header">{loginPageData.elements.header_text.value}</h3>
                                <div className="row m-t-40 input-row">
                                    <div className="col-md-6 col-lg-6">
                                        <div className="mb-3">
                                            <input className="form-control logininput" type='text' placeholder={loginPageData.elements.first_text_box_placeholder.value} onChange={(e) => setUserName(e.target.value)}></input>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6">
                                        <div className="mb-3">
                                            <input type='text' className="form-control logininput" placeholder={loginPageData.elements.second_textbox_placeholder_text.value} onChange={(e) => setAccessCode(e.target.value)}></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mb-3">
                                        <button type='button' className='btn btn-primary mt-3 mb-3 loginbutton' onClick={login}>{loginPageData.elements.button_text.value}</button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </section>

                    {showValidationError && errors.length > 0 &&
                        <ul className='errorsummary pt-1'>
                            {errors.map((e, i) => {
                                return <> <li>{e}</li></>
                            })}
                        </ul>
                    }

                    <section id="login-content">
                        <div className="container">
                            <div className="row">
                                <div className='note pt-5 pb-5'
                                    dangerouslySetInnerHTML={{ __html: loginPageData.elements.note.value }}>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            )}
            <Footer />
        </>
    )
}

export default LoginPage;